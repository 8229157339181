<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />
    <Footer logged />

    <div class="page-title page-title-fixed">
      <div>
        <h1 class="font-18">Edit Akun</h1>
      </div>
    </div>

    <div class="page-title-clear"></div>

    <div class="page-content">
      <!-- Basic Account  -->
      <div class="card card-style">
        <div class="content">
          <p class="mb-n1 color-highlight font-600 font-12">Edit Akun Anda</p>
          <h4 class="mb-3">Informasi Dasar</h4>

          <!-- Form -->
          <div
            class="input-style no-borders has-icon validate-field"
            :class="{ 'input-style-active': dataAccount.email }"
          >
            <i class="fa fa-at"></i>
            <input
              type="email"
              class="form-control validate-email"
              id="form1ac"
              placeholder="Alamat Email"
              v-model="dataAccount.email"
              required
            />
            <label for="form1ac">Alamat Email</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800': errors.email }"
              >(required)</em
            >
          </div>
          <div
            class="input-style no-borders has-icon validate-field"
            :class="{ 'input-style-active': dataAccount.mobile_no }"
          >
            <i class="fas fa-mobile-alt"></i>
            <input
              type="tel"
              class="form-control"
              id="formmn"
              placeholder="Nomor Hp"
              v-model="dataAccount.mobile_no"
              max="12"
              required
            />
            <label for="formmn">Nomor Hp</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800': errors.mobile_no }"
              >(required)</em
            >
          </div>

          <div
            class="input-style no-borders has-icon"
            :class="{ 'input-style-active': dataAccount.gender }"
          >
            <i class="fas fa-venus-mars"></i>
            <label for="form5a">Jenis Kelamin</label>
            <select
              class="form-control"
              id="form5a"
              v-model="dataAccount.gender"
            >
              <option disabled value="">Jenis Kelamin</option>
              <option value="M">Laki - Laki</option>
              <option value="F">Perempuan</option>
            </select>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <i class="fa fa-check disabled invalid color-red-dark"></i>
            <em :class="{ 'color-red-dark font-800': errors.gender }"
              >(required)</em
            >
          </div>

          <div
            class="input-style no-borders has-icon"
            :class="{ 'input-style-active': dataAccount.datebirth }"
          >
            <i class="far fa-calendar-alt"></i>
            <input
              :type="dateInput ? 'date' : 'text'"
              @focus="dateInput = true"
              max="2015-01-01"
              class="custom-form-control validate-text"
              id="form6a"
              v-model="dataAccount.datebirth"
              required
              placeholder="Tanggal Lahir"
            />
            <label for="form6a">Tanggal Lahir</label>
            <i
              class="
                fa fa-check
                disabled
                valid
                me-4
                pe-3
                font-12
                color-green-dark
              "
            ></i>
            <i
              class="
                fa fa-times
                disabled
                invalid
                me-4
                pe-3
                font-12
                color-red-dark
              "
            ></i>
            <em :class="{ 'color-red-dark font-800': errors.datebirth }"
              >(required)</em
            >
          </div>

          <!-- Submit Form Button -->
          <a
            @click.prevent="submitForm"
            href="#"
            class="
              btn btn-full btn-m
              gradient-highlight
              rounded-s
              font-13 font-600
              mt-4
            "
            >Simpan Perubahan</a
          >
          <!--End Form -->
        </div>
      </div>
      <!--End Basic Account  -->
      <div class="card card-style">
        <div class="content">
          <p class="mb-n1 color-highlight font-600 font-12">Edit Akun Anda</p>
          <h4>Ubah Password</h4>
          <p v-if="errors" class="color-red-dark mb-3">
            {{ errors.errorPass }}
          </p>
          <!-- Form Change Password -->
          <div
            class="input-style no-borders has-icon validate-field"
            :class="{ 'input-style-active': password }"
          >
            <i class="fa fa-lock"></i>
            <input
              type="password"
              class="form-control validate-password"
              id="form1ad"
              placeholder="Kata Sandi sebelumnya"
              v-model="oldPassword"
              required
            />
            <label for="form1ad">Kata Sandi sebelumnya</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800': errors.password }"
              >(required)</em
            >
          </div>

          <div
            class="input-style no-borders has-icon validate-field mt-3"
            :class="{ 'input-style-active': password }"
          >
            <i class="fa fa-lock"></i>
            <input
              type="password"
              class="form-control validate-password"
              id="form1ad"
              placeholder="Kata sandi baru"
              v-model="password"
              required
            />
            <label for="form1ad">Kata sandi baru</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800': errors.password }"
              >(required)</em
            >
          </div>

          <div
            class="input-style no-borders has-icon validate-field"
            :class="{ 'input-style-active': password2 }"
          >
            <i class="fa fa-lock"></i>
            <input
              type="password"
              class="form-control validate-password"
              id="form1ae"
              placeholder="Kata sandi baru"
              v-model="password2"
              required
            />
            <label for="form1ae">Kata Sandi</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800': errors.password2 }"
              >(required)</em
            >
          </div>
          <!--End Form Change Password -->
          <!-- Submit Form Button -->
          <a
            @click.prevent="changePassword"
            href="#"
            class="
              btn btn-full btn-m
              gradient-highlight
              rounded-s
              font-13 font-600
              mt-4
            "
            >Ubah Password</a
          >
        </div>
      </div>
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Account",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      business_name: "",
      dataAccount: {
        email: "",
        mobile_no: "",
        gender: "",
        datebirth: "",
      },
      dateInput: false,
      oldPassword: "",
      password: "",
      password2: "",
      master_data: {},
      api_url: "/api/v1/auth/user/",
      method: "post",
      isLoading: true,
      errors: {},
    };
  },
  props: {
    data: Object,
  },
  mounted() {
    init_template();
    this.getData();
  },
  methods: {
    menuOpen,
    menuClose,
    async getData() {
      this.errors = [];
      await axios
        .get(this.api_url)
        .then((response) => {
          this.dataAccount = response.data;
          console.log(response.data);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log("error :", JSON.stringify(error));
          }
        });
    },
    /*update data*/
    async submitForm() {
      const formData = this.dataAccount;
      await axios
        .post(this.api_url, formData)
        .then((response) => {
          this.dataAccount = response.data;
          this.$router.push({ name: "Dashboard" });
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors[
                "error"
              ] = `${property}: ${error.response.data[property]}`;
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors["error"] = "Something went wrong. please try again";
            console.log(JSON.stringify(error));
          }
        });
    },
    validatePassword() {
      // password must match
      if (this.password !== this.password2) {
        this.errors["password"] = "kata sandi  tidak sama";
        this.errors["password2"] = "kata sandi tidak sama";
        this.errors["errorPass"] = "kata sandi tidak sama";
      }

      // password not lest 8 characters
      if (this.password.length <= 8) {
        this.errors["password"] = "kata sandi kurang dari 8 characters";
        this.errors["password2"] = "kata sandi kurang dari 8 characters";
        this.errors["errorPass"] =
          "kata sandi tidak boleh kurang dari 8 karakter";
      }
      if (this.oldPassword === "") {
        this.errors["errorPass"] = "kata sandi lama tidak boleh kosong";
      }
    },
    async changePassword() {
      this.validatePassword();
      console.log("");
      if (this.errors.length === 0) {
        console.log("submit change password");
        const formPassword = {
          old_password: this.oldPassword,
          new_password: this.password,
        };
        await axios
          .patch("/api/v1/auth/change-password/", formPassword)
          .then((response) => {
            this.dataAccount = response;
          this.$router.push({ name: "Dashboard" });
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors[
                  "error"
                ] = `${property}: ${error.response.data[property]}`;
                console.log(error.response.data[property][0]);
                if (error.response.data[property][0] === "Wrong password.") {
                  console.log("wrong old password !");
                  this.errors.errorPass = "Kata sandi lama salah";
                }
              }

              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors["error"] = "Something went wrong. please try again";
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
